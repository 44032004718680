import * as types from '../actions/actionTypes'

const initialState = {
    items: {}, cursor: {}, total: {}
}

export default function data(state = initialState, action) {
    switch (action.type) {
        case types.DATA_FETCH_SUCCESS:
            if (action.cursor === 0) {
                state.items[action.key] = action.data.items
                state.cursor[action.key] = action.data.next
                state.total[action.key] = action.data.total
                return Object.assign({}, state)
            } else {
                state.items[action.key] = state.items[action.key].concat(action.data.items)
                state.cursor[action.key] = action.data.next
                return Object.assign({}, state)
            }
        case types.DATA_FETCH_FAILURE:
            state.items[action.key] = []
            state.cursor[action.key] = 0
            state.total[action.key] = 0
            return Object.assign({}, state)

        case types.DATA_UPDATE_SUCCESS:
            state.items[action.key].map(item => {
                if (item.id === action.data.id) return action.data
                else return item
            })
            return Object.assign({}, state)

        default:
            return state
    }
}
