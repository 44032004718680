import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'


export default class PanelHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {keyword: this.props.search || ''}

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        e.preventDefault()
        e.stopPropagation()

        let changed = {}
        changed[e.target.id] = e.target.value
        this.setState(Object.assign({}, this.state, changed), () => {
            if (this.props.onSearchChanged) {
                this.props.onSearchChanged(this.state.keyword)
            }
        })
    }

    render() {
        const {title, count, button, onButtonClick, onSearchChanged} = this.props
        const {keyword} = this.state

        return (
            <div className='panel-header no-select'>
                <div className='items'>
                    <span className='title'>{title} {(count && count > 0) ? count : ''}</span>

                    <div className='center'>
                        {this.props.children}
                    </div>

                    {onSearchChanged && (
                        <FormControl id='keyword' value={keyword} onChange={e => this.handleChange(e)}
                                     placeholder='Search'/>
                    )}

                    {button && (
                        <div className='button clickable no-select' onClick={() => {
                            if (onButtonClick) onButtonClick()
                        }}>{button}</div>
                    )}
                </div>
            </div>
        )
    }
}

PanelHeader.defaultProps = {
    title: '',
    count: undefined,

    button: undefined,
    onButtonClick: undefined,

    search: '',
    onSearchChanged: undefined
}

PanelHeader.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number,

    button: PropTypes.string,
    onButtonClick: PropTypes.func,

    search: PropTypes.string,
    onSearchChanged: PropTypes.func
}