import moment from 'moment'
import { cloneDeep, merge } from 'lodash'
import { v4 as uuidv4 } from 'uuid'


export function generateUUID(suffix = '') {
    return suffix.length === 0 ? uuidv4() : `${uuidv4()}.${suffix}`
}

export function timestampToDate(timestamp) {
    return timestamp ? moment(timestamp.toDate()).format('YYYY-MM-DD HH:mm:ss') : ''
}

export function parseDateString(dateString) {
    const parsed = moment(dateString, "YYYY-MM-DD")
    return {valid: parsed.isValid(), value: parsed.toDate()}
}

export function addComma(num='') {
    const reg = /\B(?=(\d{3})+(?!\d))/g;
    if (num == null) return '';
    return num.toString().replace(reg, ',');
}

export function removeComma(str='') {
    return replaceAll(str, ',', '');
}

export function parseNumber(str) {
    let int = parseInt(str)
    let float = parseFloat(str)
    if (!isNaN(int) && !isNaN(float)) return Number(str)
    else return 0
}

export function validateCoordinates(coordinates = []) {
    if (coordinates.length !== 2) return false
    let lng = parseFloat(coordinates[0])
    let lat = parseFloat(coordinates[1])
    return !isNaN(lng) && !isNaN(lat)
}

export function leadingZero(value, size = 1) {
    let s = `${value}`
    while (s.length < size) {s = '0' + s;}
    return s;
}

export function replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
}

export function getTimezone() {
    return new Date().getTimezoneOffset() * -1
}

export function handleInput(el, origin = {}, customType) {
    const applyNumber = (value) => {
        return parseNumber(value.endsWith('.') ? value + '0' : value)
    }
    const prepareArray = (data, key, index) => {
        if (!data.hasOwnProperty(key)) {
            data[key] = []
        }

        if (index > 0 && data[key].length < index) {
            for (let i = data[key].length ; i < index ; i++) {
                if (type === 'number') data[key].push(0)
                else data[key].push('')
            }
        }
    }

    const id = el.target.id
    const type = customType || el.target.type
    const value = el.target.value

    let changed = cloneDeep(origin)

    if (id.includes('.')) {
        let paths = id.split('.')
        let appliedPaths = []
        paths.forEach((path, idx) => {
            let local = changed
            appliedPaths.forEach(applied => local = local[applied])
            appliedPaths.push(path)

            if (idx < paths.length - 1) {
                if (!local.hasOwnProperty(path)) local[path] = {}
            } else {
                if (path.includes('@')) {
                    let keyIndex = path.split('@')
                    const key = keyIndex[0]
                    const index = keyIndex[1]

                    prepareArray(local, key, index)

                    if (type === 'number') local[key][index] = applyNumber(value)
                    else if (type === 'boolean') local[key][index] = (value === 'true')
                    else local[key][index] = value
                } else {
                    if (type === 'number') local[path] = applyNumber(value)
                    else if (type === 'boolean') local[path] = (value === 'true')
                    else local[path] = value
                }
            }
        })
    } else {
        if (id.includes('@')) {
            let keyIndex = id.split('@')
            const key = keyIndex[0]
            const index = keyIndex[1]

            prepareArray(changed, key, index)

            if (type === 'number') changed[key][index] = applyNumber(value)
            else if (type === 'boolean') changed[key][index] = (value === 'true')
            else changed[key][index] = value
        } else {
            if (type === 'number') changed[id] = applyNumber(value)
            else if (type === 'boolean') changed[id] = (value === 'true')
            else changed[id] = value
        }
    }

    return merge(origin, changed)
}