import * as firebase from 'firebase'
import 'firebase/firebase-firestore'

const config = {
    apiKey: "AIzaSyDJaUsUYTojZk4h8JjhUxuaRwwGCNnXX4g",
    authDomain: "qq-wallet.firebaseapp.com",
    databaseURL: "https://qq-wallet.firebaseio.com",
    projectId: "qq-wallet",
    storageBucket: "qq-wallet.appspot.com",
    messagingSenderId: "484873487359",
    appId: "1:484873487359:web:7b302230d07a9c285bddca",
    measurementId: "G-TBP967P8V1"
}

firebase.initializeApp(config)

export default firebase