import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'
import Spinner from 'react-spinkit'
import { updateProgress } from '../../actions/nav'
import { withAlert } from 'react-alert'
import './Root.scss'

import firebase from '../../Firebase'


class Root extends Component {
    constructor(props) {
        super(props)
        this.state = {user: undefined}

        firebase.auth().onAuthStateChanged(user => {
            this.props.updateProgress(true)
            this.handleAdmin(user).then(() => {
                this.props.updateProgress(false)
            })
        })
    }

    componentDidMount() {
        Modal.setAppElement('#root')
        this.handleScreen(true)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.handleScreen(true)
    }

    async handleAdmin(user) {
        const signOut = async () => {
            await firebase.auth().signOut()
            this.setState({user: undefined})
        }

        if (user) {
            const uid = user.uid
            const adminSnap = await firebase.firestore().collection('admins').doc(uid).get()
            const admin = adminSnap.data()
            if (adminSnap.exists && admin.allow) {
                this.setState({user: user})
            } else {
                await signOut()
            }
        } else {
            await signOut()
        }
    }

    handleScreen(shouldNavigate = false) {
        const {location} = this.props
        const {user} = this.state

        switch (location.pathname) {
            case 'console':
            case '/console':
                if (!user) {
                    if (shouldNavigate) {
                        this.props.history.replace('/login')
                    } else {
                        return null
                    }
                }
                break

            case 'login':
            case '/login':
                if (user) {
                    if (shouldNavigate) {
                        this.props.history.replace('/console')
                    } else {
                        return null
                    }
                }
                break

            default:
                break
        }
    }

    render() {
        this.handleScreen(false)

        const {children, navProgress} = this.props
        return (
            <div className='root'>
                {children}

                {navProgress && (
                    <div className='root-progress'>
                        <Spinner name='chasing-dots' color='#FF9600'/>
                    </div>
                )}
            </div>
        )
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        updateProgress: (progress) => dispatch(updateProgress(progress))
    }
}

let mapStateToProps = (state) => {
    return {
        navProgress: state.nav.progress
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(Root)))