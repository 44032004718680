import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ConsoleMenu from './ConsoleMenu'
import { UserPanel, WalletPanel } from '../../components/panels'
import './Console.scss'


class Console extends Component {
    constructor(props) {
        super(props)
        this.state = {attached: false}
    }

    componentDidMount() {
        this.setState({attached: true})
    }

    componentWillUnmount() {
        this.setState({attached: false})
    }

    renderPanels() {
        const {navTypes} = this.props
        switch (this.props.active) {
            case navTypes.users:
                return <UserPanel panelKey={navTypes.users}/>
            case navTypes.wallets:
                return <WalletPanel panelKey={navTypes.wallets}/>
            default:
                return <UserPanel panelKey={navTypes.users}/>
        }
    }

    renderMenu() {
        const {navTypes} = this.props
        return (
            <div className='console-menu no-select'>
                <div className='list'>
                    <ConsoleMenu type={navTypes.users}/>
                    <ConsoleMenu type={navTypes.wallets}/>
                    <div className='spacer'/>
                    <ConsoleMenu type={navTypes.logout}/>
                </div>
                <div className='copyright'>© 2020 Artify Inc.<br/>All Rights Reserved.</div>
            </div>
        )
    }

    render() {
        const {attached} = this.state
        if (!attached) return null

        return (
            <div className='console'>
                <div className='column menu'>{this.renderMenu()}</div>
                <div className='column view'>{this.renderPanels()}</div>
            </div>
        )
    }
}

let mapDispatchToProps = (dispatch) => {
    return {}
}

let mapStateToProps = (state) => {
    return {
        navTypes: state.nav.navTypes,
        active: state.nav.active
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Console))
