import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import CompanyNotice from '../views/CompanyNotice'
import { AssetIconLanding, AssetIconAppstore, AssetIconPlaystore } from '../../assets'
import './Landing.scss'


class Landing extends Component {
    render() {
        return (
            <div className='landing'>
                <div className='landing-content no-select'>
                    <div className='welcome'>
                        <img className='logo' alt='welcome' src={AssetIconLanding}/>
                        <div className='markets'>
                            <a href={''} target='_blank' rel='noopener noreferrer'>
                                <img className='store-icon-big' alt='AppStore' src={AssetIconAppstore}/>
                            </a>
                            <a href={''} target='_blank' rel='noopener noreferrer'>
                                <img className='store-icon-big' alt='PlayStore' src={AssetIconPlaystore}/>
                            </a>
                        </div>
                        <CompanyNotice/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Landing)