import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import './SlideModal.scss'
import Spinner from 'react-spinkit'


class SlideModal extends Component {
    constructor(props) {
        super(props)
    }

    onAfterOpen() {
        const rootWrapper = document.getElementById('root')
        rootWrapper.style.position = 'fixed'
        rootWrapper.style.top = `-${this.bodyScrollPos}px`
    }

    onRequestClose() {
        const rootWrapper = document.getElementById('root')
        rootWrapper.style.removeProperty('position')
        rootWrapper.style.removeProperty('top')

        if (this.props.onRequestClose) {
            this.props.onRequestClose()
        }
    }

    onRequestSave() {
        if (this.props.onRequestSave) {
            this.props.onRequestSave()
        }
    }

    render() {
        return (
            <Modal className='slide-modal slide-modal-from-right' overlayClassName='slide-modal-overlay' closeTimeoutMS={500}
                   isOpen={this.props.isOpen}
                   onAfterOpen={() => this.onAfterOpen()}
                   onRequestClose={() => this.onRequestClose()}
                   contentLabel={this.props.title}>

                <div className='slide-modal-header no-select'>
                    <div className='slide-modal-close' onClick={() => this.onRequestClose()}>close</div>

                    {this.props.onRequestSave && (
                        <div className='slide-modal-save' onClick={() => this.onRequestSave()}>save</div>
                    )}

                    <div className='slide-modal-header-title'>{this.props.title}</div>
                </div>

                <div className='slide-modal-content'>
                    {this.props.children}
                </div>

                {this.props.navProgress && (
                    <div className='root-progress'>
                        <Spinner name='chasing-dots' color='#FF9600'/>
                    </div>
                )}
            </Modal>
        )
    }
}

SlideModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func,
    onRequestSave: PropTypes.func,
    title: PropTypes.string
}

let mapDispatchToProps = (dispatch) => {
    return {
    }
}

let mapStateToProps = (state) => {
    return {
        navProgress: state.nav.progress
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlideModal))