import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { navigateTo, updateProgress } from '../../actions/nav'
import { withRouter } from 'react-router-dom'
import PanelHeader from '../views/PanelHeader'
import SlideModal from '../modal/SlideModal'
import { withAlert } from 'react-alert'
import { Col, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { generateUUID, handleInput, parseDateString, timestampToDate } from '../../modules/utils'
import { AssetIconLanding } from '../../assets'
import './Panel.scss'

import firebaseApp from '../../Firebase'


const Modal = {Addition: 'addition', Detail: 'detail'}

class WalletPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [], data: [], count: 0, next: undefined,

            members: [], coupons: [],
            isModalOpen: undefined,
            active: undefined,

            addition: {name: '', type: 'coupon', barcode: '', expiredAt: ''},
            additionPreview: null, additionFile: null
        }

        this.onScroll = this.onScroll.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handlePhoto = this.handlePhoto.bind(this)
    }

    componentDidMount() {
        this.getStatistics()
        this.getSnapshot()
        this.fileSelector = this.buildFileSelector()
        window.addEventListener('scroll', this.onScroll, true)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevPanelKey = prevProps.panelKey
        const nextPanelKey = this.props.panelKey
        if (prevPanelKey !== nextPanelKey) {
            this.getStatistics()
            this.getSnapshot()
        }
    }

    buildFileSelector() {
        const fileSelector = document.createElement('input')
        fileSelector.setAttribute('type', 'file')
        fileSelector.setAttribute('accept', 'image/*')
        fileSelector.onchange = (e) => this.handlePhoto(e)
        return fileSelector
    }

    onScroll(e) {
        if (this.state.isModalOpen) return

        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (this.state.next) {
                this.getSnapshot(this.state.next)
            }
        }
    }

    handleSearch(search = '') {
        const {data} = this.state

        const fieldValues = search.trim().split(':')
        const field = fieldValues.shift().trim()
        const value = fieldValues.join(':').trim()
        if (field.length > 0 && value.length > 0) {
            firebaseApp.firestore().collection('wallets')
                .where(field, '>=', value)
                .where(field, '<=', `${value}\uf8ff`)
                .get()
                .then(snapshot => {
                    const result = []
                    snapshot.forEach(doc => {
                        let data = doc.data()
                        if (data.id) {
                            result.push(data)
                        }
                    })
                    this.setState({items: result})
                })
        } else {
            this.setState({items: data})
        }
    }

    handleChange(e, customType) {
        e.preventDefault()
        e.stopPropagation()
        this.setState(handleInput(e, this.state, customType))
    }

    handlePhoto(e) {
        e.preventDefault()
        e.stopPropagation()

        let file = e.target.files[0]
        if (file) {
            this.setState({additionPreview: URL.createObjectURL(file), additionFile: file})
        }
    }

    getStatistics() {
        firebaseApp.firestore().collection('admins').doc('statistics').get().then(snapshot => {
            let data = snapshot.data()
            this.setState({count: data.wallets})
        })
    }

    getSnapshot(cursor) {
        const {navItem} = this.props
        const result = []

        this.props.updateProgress(true)
        try {
            const defaultQuery = firebaseApp.firestore().collection('wallets').orderBy('createdAt', 'desc')
            const cursorQuery = cursor ? defaultQuery.startAfter(cursor) : defaultQuery

            cursorQuery.limit(10).get().then(snapshot => {
                snapshot.forEach(doc => {
                    let data = doc.data()
                    if (data.id) {
                        result.push(data)
                    }
                })

                const last = snapshot.docs[snapshot.docs.length - 1]
                const next = last ? last.data().createdAt : undefined
                const data = cursor ? this.state.data.concat(result) : result

                this.setState({items: data, data: data, next: next}, () => {
                    this.props.updateProgress(false)
                    if (navItem && navItem.trim().length > 0) {
                        this.handleSearch(navItem.trim())
                    }
                })
            })
        } catch (error) {
            this.props.alert.show(error.toString())
            this.props.updateProgress(false)
        }
    }

    async getMembers(active) {
        const result = []
        for (const member of active.members) {
            let memberSnapshot = await member.get()
            result.push(memberSnapshot.data())
        }
        return result
    }

    async getCoupons(active) {
        const result = []

        const walletRef = firebaseApp.firestore().collection('wallets').doc(active.id)
        if (active.isPrivate) {
            const snapshot = await firebaseApp.firestore().collection('items')
                .where('owner', '==', walletRef)
                .orderBy('createdAt', 'desc')
                .get()
            snapshot.forEach(doc => {
                result.push(doc.data())
            })
        } else {
            const snapshot = await firebaseApp.firestore().collection('items')
                .where('shared', 'array-contains', walletRef)
                .orderBy('createdAt', 'desc')
                .get()
            snapshot.forEach(doc => {
                result.push(doc.data())
            })
        }
        return result
    }

    async onClickItem(item) {
        this.props.updateProgress(true)

        const members = await this.getMembers(item)
        const coupons = await this.getCoupons(item)
        this.setState({
            isModalOpen: Modal.Detail, active: item, members: members, coupons: coupons,

            addition: {photoUrl: null, name: '', type: 'coupon', barcode: '', expiredAt: ''},
            additionPreview: null, additionFile: null
        }, () => {
            this.props.updateProgress(false)
        })
    }

    async upload(active, addition, file) {
        const uploadFile = (file) => {
            const filename = generateUUID('jpg')
            const storageRef = firebaseApp.storage().ref()
            const itemImageRef = storageRef.child(`items/${filename}`)
            let uploadTask = itemImageRef.put(file, {contentType: 'image/jpeg'})

            return new Promise((resolve, reject) => {
                uploadTask.on(firebaseApp.storage.TaskEvent.STATE_CHANGED, function (snapshot) {

                }, function (error) {
                    reject(error)
                }, function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => resolve(downloadURL))
                })
            })

        }

        this.props.updateProgress(true)

        let walletRef = firebaseApp.firestore().collection('wallets').doc(active.id)

        let photoUrl = (addition.photoUrl || '').trim()
        let name = (addition.name || '').trim()
        let type = (addition.type || '').trim()
        let barcode = (addition.barcode || '').trim()
        if (name.length === 0 || type.length === 0 || barcode.length === 0) {
            this.props.updateProgress(false)
            this.props.alert.show('Check name, type, barcode')
            return
        }

        let owner = active.owner
        let shared = active.isPrivate ? [] : [walletRef]
        let createdAt = firebaseApp.firestore.Timestamp.now()
        let updatedAt = active.isPrivate ? null : createdAt
        let expiredAt = null
        let usedAt = null
        let usedCount = 0

        let expiredAtString = (addition.expiredAt || '').trim()
        if (expiredAtString.length > 0) {
            let expiredAtDate = parseDateString(expiredAtString)
            if (expiredAtDate.valid) {
                expiredAt = firebaseApp.firestore.Timestamp.fromDate(expiredAtDate.value)
            }
        }

        if (file) {
            try {
                photoUrl = await uploadFile(file)
            } catch (error) {
                this.props.updateProgress(false)
                this.props.alert.show(error.toString())
                return
            }
        }

        let payload = {
            photoUrl: photoUrl,
            name: name, type: type, barcode: barcode,
            owner: owner, shared: shared,
            createdAt: createdAt, updatedAt: updatedAt, expiredAt: expiredAt, usedAt: usedAt, usedCount: usedCount
        }

        let newItemRef = firebaseApp.firestore().collection('items').doc()
        await newItemRef.set({id: newItemRef.id, ...payload})

        const coupons = await this.getCoupons(active)
        this.setState({
            coupons: coupons,
            addition: {photoUrl: null, name: '', type: 'coupon', barcode: '', expiredAt: ''},
            additionPreview: null, additionFile: null
        }, () => {
            this.props.updateProgress(false)
            this.props.alert.show('Success')
        })
    }

    renderDetail() {
        const memberViews = (active, members = []) => {
            let views = members.map(item => {
                const isOwner = active.owner.path.includes(item.id)
                return (
                    <div key={item.id} className='sub-item' onClick={() => this.props.navigateTo('users', `id:${item.id}`)}>
                        <img className='profile' src={item.image} alt={item.name}/>
                        <div className='name'>{item.name}</div>
                        <div className='role'>{isOwner ? 'owner' : 'member'}</div>
                    </div>
                )
            })
            return <div className='users'>{views}</div>
        }

        const couponViews = (active, coupons = []) => {
            let views = coupons.map(item => {
                const ownerId = item.owner.path.split('/').pop()
                return (
                    <div key={item.id} className='sub-item'>
                        <img className='profile' src={item.photoUrl} alt={item.name}/>
                        <div className='detail'>
                            <div className='id'>UUID {item.id}</div>
                            <div className='info'>{item.name ? item.name : 'noname'} [{item.type} {item.barcode}]</div>
                            <div className='info' onClick={() => this.props.navigateTo('users', `id:${ownerId}`)}>owner: {ownerId}</div>

                            <div className='stats'>
                                <span className='info'>create: {timestampToDate(item.createdAt)}</span>
                                <span className='info'>expire: {timestampToDate(item.expiredAt)}</span>
                                <span className='info'>use: {timestampToDate(item.usedAt)}</span>
                            </div>
                        </div>
                    </div>
                )
            })
            return <div className='posts'>{views}</div>
        }

        const inputView = (active) => {
            const {addition, additionPreview, additionFile} = this.state
            return (
                <div className='posts'>
                    <div className='sub-item'>
                        {additionPreview ? (
                            <img className='preview' alt='addition' src={additionPreview} onClick={() => this.fileSelector.click()}/>
                        ) : (
                            <img className='preview' alt='addition' src={AssetIconLanding} onClick={() => this.fileSelector.click()}/>
                        )}

                        <div className='detail'>
                            <FormGroup className='input-field'>
                                <Form.Row>
                                    <Col>
                                        <FormLabel>NAME</FormLabel>
                                        <FormControl id='addition.name' value={addition.name} onChange={e => this.handleChange(e)}/>
                                    </Col>
                                    <Col>
                                        <FormLabel>TYPE</FormLabel>
                                        <FormControl id='addition.type' as='select' value={addition.type} onChange={e => this.handleChange(e, 'string')}>
                                            <option value='coupon'>coupon</option>
                                            <option value='membership'>membership</option>
                                        </FormControl>
                                    </Col>
                                </Form.Row>
                            </FormGroup>
                            <FormGroup className='input-field'>
                                <Form.Row>
                                    <Col>
                                        <FormLabel>BARCODE</FormLabel>
                                        <FormControl id='addition.barcode' value={addition.barcode} onChange={e => this.handleChange(e)}/>
                                    </Col>
                                    <Col>
                                        <FormLabel>EXPIRE (YYYY-MM-DD)</FormLabel>
                                        <FormControl id='addition.expiredAt' placholder='yyyy-MM-dd' value={addition.expiredAt} onChange={e => this.handleChange(e)}/>
                                    </Col>
                                </Form.Row>
                            </FormGroup>
                            <FormGroup className='input-field'>
                                <Form.Row>
                                    <Col>
                                        <div className='button clickable no-select' onClick={() => this.upload(active, addition, additionFile)}>ADD</div>
                                    </Col>
                                </Form.Row>
                            </FormGroup>

                        </div>
                    </div>
                </div>
            )
        }

        const {isModalOpen, active, members, coupons} = this.state
        if (isModalOpen !== Modal.Detail) return null
        if (!active) return null

        return (
            <SlideModal isOpen={isModalOpen === Modal.Detail}
                        onRequestClose={() => this.setState({isModalOpen: undefined, active: undefined})}
                        title={active.name}>
                <div className='panel-modal'>
                    {memberViews(active, members)}
                    {inputView(active)}
                    {couponViews(active, coupons)}
                </div>
            </SlideModal>
        )
    }

    renderItems() {
        const {items} = this.state
        let views = items.map(item => {
            return (
                <div key={item.id} className='panel-item' onClick={() => this.onClickItem(item)}>
                    <div className='detail'>
                        <div className='id'>id: {item.id}</div>
                        <div className='info'>name: {item.name}</div>
                        <div className='account'>{item.owner.path}</div>
                        <div className='account'>{timestampToDate(item.createdAt)}</div>

                        <div className='stats'>
                            <span className='info'>{item.isPrivate ? 'private' : 'shared'}</span>
                            <span className='info'>invitation: {item.invitationState ? 'on' : 'off'} [{item.invitationCode}]</span>
                            <span className='info'>members: {item.members.length}</span>
                        </div>
                    </div>
                </div>
            )
        })
        return <div>{views}</div>
    }

    render() {
        const {navItem} = this.props
        return (
            <div className='panel-container' onScroll={this.onScroll}>
                <PanelHeader title={this.props.panelKey.toUpperCase()}
                             count={this.state.count}
                             search={navItem} onSearchChanged={search => this.handleSearch(search)}/>

                <div className='body'>{this.renderItems()}</div>

                {this.renderDetail()}
            </div>
        )
    }
}

WalletPanel.propTypes = {
    panelKey: PropTypes.string.isRequired
}

let mapDispatchToProps = (dispatch) => {
    return {
        navigateTo: (target, item) => dispatch(navigateTo(target, item)),
        updateProgress: (progress) => dispatch(updateProgress(progress))
    }
}

let mapStateToProps = (state) => {
    return {
        navItem: state.nav.item
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(WalletPanel)))
