import * as types from '../actions/actionTypes'

const navTypes = {
    users: 'users',
    wallets: 'wallets',
    logout: 'logout'
}

const initialState = {
    navTypes: navTypes,
    active: navTypes.users, item: null,
    progress: false
}

export default function nav(state = initialState, action) {
    switch (action.type) {
        case types.NAVIGATE_TO:
            return Object.assign({}, state, {active: action.active, item: action.item})
        case types.UPDATE_PROGRESS:
            return Object.assign({}, state, {progress: action.progress})
        default:
            return state
    }
}
