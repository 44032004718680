/**
 * nav
 * */
export const NAVIGATE_TO = 'NAVIGATE_TO'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'


/**
 * data
 * */
export const DATA_FETCH_SUCCESS = 'DATA_FETCH_SUCCESS'
export const DATA_FETCH_FAILURE = 'DATA_FETCH_FAILURE'

export const DATA_UPDATE_SUCCESS = 'DATA_UPDATE_SUCCESS'
export const DATA_UPDATE_FAILURE = 'DATA_UPDATE_FAILURE'