import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { FormControl } from 'react-bootstrap'
import { withAlert } from 'react-alert'
import './Login.scss'

import firebase from '../../Firebase'


class Console extends Component {
    constructor(props) {
        super(props)
        this.state = {email: '', password: ''}

        this.handleChange = this.handleChange.bind(this)
        this.onLogin = this.onLogin.bind(this)
    }

    handleChange(e) {
        e.preventDefault()
        e.stopPropagation()

        let changed = {}
        changed[e.target.id] = e.target.value

        this.setState(Object.assign({}, this.state, changed))
    }

    async onLogin() {
        const {email, password} = this.state
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password)
        } catch (error) {
            this.props.alert.show(error.toString())
        }
    }

    render() {
        return (
            <div className='login'>
                <header className='login-auth'>
                    <FormControl id='email' type='text' placeholder='name' value={this.state.name} onChange={this.handleChange}/>
                    <FormControl id='password' type='password' placeholder='pass' value={this.state.pass} onChange={this.handleChange}/>
                    <div className='login-button clickable no-select' onClick={this.onLogin}>ACCESS</div>
                </header>
            </div>
        )
    }
}

export default withRouter(withAlert()(Console))