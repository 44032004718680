import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { navigateTo } from '../../actions/nav'
import firebase from '../../Firebase'

class ConsoleMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {hover: false}
    }

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    async onClickItem(type) {
        const {navTypes} = this.props
        if (type === navTypes.logout) {
            await firebase.auth().signOut()
        } else {
            this.props.navigateTo(type)
        }
    }

    render() {
        const {type} = this.props
        const active = this.props.active === type
        return (
            <div className={`item clickable ${active && 'active'}`} onClick={() => this.onClickItem(type)}
                 onMouseEnter={() => this.toggleHover(true)} onMouseLeave={() => this.toggleHover(false)}>
                <span className='clickable'>{type.toUpperCase()}</span>
            </div>
        )
    }
}

ConsoleMenu.propTypes = {
    type: PropTypes.string,
    active: PropTypes.string
}

let mapDispatchToProps = (dispatch) => {
    return {
        navigateTo: (target) => dispatch(navigateTo(target))
    }
}

let mapStateToProps = (state) => {
    return {
        navTypes: state.nav.navTypes,
        active: state.nav.active
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsoleMenu)
