import React, { Component } from 'react'


export default class CompanyNotice extends Component {
    render() {
        return (
            <div className='company-notice no-select'>
                <span>수연 주식회사</span>
                <div className='agreements'>
                    <a href='/terms.html' target='_blank' rel='noopener noreferrer' className='agreement-item'>서비스 이용약관</a>
                    <span className='divider'>|</span>
                    <a href='/privacy.html' target='_blank' rel='noopener noreferrer' className='agreement-item'>개인정보취급방침</a>
                </div>

                <span className='misc'>대표 김태정 | 사업자등록번호 295-81-01630</span>
                <span className='misc'>서울특별시 영등포구 국제금융로6길 33, 919호 비210</span>
                <span className='misc'>qqwakqqwak@gmail.com</span>
            </div>
        )
    }
}
