import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { navigateTo, updateProgress } from '../../actions/nav'
import { withRouter } from 'react-router-dom'
import PanelHeader from '../views/PanelHeader'
import SlideModal from '../modal/SlideModal'
import { withAlert } from 'react-alert'
import { handleInput } from '../../modules/utils'
import './Panel.scss'

import firebaseApp from '../../Firebase'


const Modal = {Addition: 'addition', Detail: 'detail'}


class UserPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [], data: [], count: 0, next: undefined,

            wallets: [],
            isModalOpen: undefined,
            active: undefined
        }

        this.onScroll = this.onScroll.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.getStatistics()
        this.getSnapshot()
        window.addEventListener('scroll', this.onScroll, true)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevPanelKey = prevProps.panelKey
        const nextPanelKey = this.props.panelKey
        if (prevPanelKey !== nextPanelKey) {
            this.getStatistics()
            this.getSnapshot()
        }
    }

    onScroll(e) {
        if (this.state.isModalOpen) return

        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (this.state.next) {
                this.getSnapshot(this.state.next)
            }
        }
    }

    handleSearch(search = '') {
        const {data} = this.state

        const fieldValues = search.trim().split(':')
        const field = fieldValues.shift().trim()
        const value = fieldValues.join(':').trim()
        if (field.length > 0 && value.length > 0) {
            firebaseApp.firestore().collection('users')
                .where(field, '>=', value)
                .where(field, '<=', `${value}\uf8ff`)
                .get()
                .then(snapshot => {
                    const result = []
                    snapshot.forEach(doc => {
                        let data = doc.data()
                        if (data.id) {
                            result.push(data)
                        }
                    })
                    this.setState({items: result})
                })
        } else {
            this.setState({items: data})
        }
    }

    handleChange(e, customType) {
        e.preventDefault()
        e.stopPropagation()
        this.setState(handleInput(e, this.state, customType))
    }

    getStatistics() {
        firebaseApp.firestore().collection('admins').doc('statistics').get().then(snapshot => {
            let data = snapshot.data()
            this.setState({count: data.users})
        })
    }

    getSnapshot(cursor) {
        const {navItem} = this.props
        const result = []

        this.props.updateProgress(true)
        try {
            const defaultQuery = firebaseApp.firestore().collection('users').orderBy('createdAt', 'desc')
            const cursorQuery = cursor ? defaultQuery.startAfter(cursor) : defaultQuery

            cursorQuery.limit(10).get().then(snapshot => {
                snapshot.forEach(doc => {
                    let data = doc.data()
                    if (data.id) {
                        result.push(data)
                    }
                })

                const last = snapshot.docs[snapshot.docs.length - 1]
                const next = last ? last.data().createdAt : undefined
                const data = cursor ? this.state.data.concat(result) : result

                this.setState({items: data, data: data, next: next}, () => {
                    this.props.updateProgress(false)
                    if (navItem && navItem.trim().length > 0) {
                        this.handleSearch(navItem.trim())
                    }
                })
            })
        } catch (error) {
            this.props.alert.show(error.toString())
            this.props.updateProgress(false)
        }
    }

    async getWallets(active) {
        const result = []

        const userRef = firebaseApp.firestore().collection('users').doc(active.id)
        const snapshot = await firebaseApp.firestore().collection('wallets').where('members', 'array-contains', userRef).get()
        snapshot.forEach(doc => {
            result.push(doc.data())
        })
        return result
    }

    async onClickItem(item) {
        this.props.updateProgress(true)

        const wallets = await this.getWallets(item)
        this.setState({isModalOpen: Modal.Detail, active: item, wallets: wallets}, () => {
            this.props.updateProgress(false)
        })
    }

    renderDetail() {
        const walletViews = (wallets = []) => {
            let views = wallets.map(item => {
                return (
                    <div key={item.id} className='sub-item' onClick={() => this.props.navigateTo('wallets', `id:${item.id}`)}>
                        <div className='detail'>
                            <div className='id'>{item.id}</div>
                            <div className='info'>{item.name}</div>
                            <div className='description'>{item.description}</div>

                            <div className='stats'>
                                <span className='info'>{item.isPrivate ? 'private' : 'shared'}</span>
                                <span className='info'>invitation: {item.invitationState ? 'on' : 'off'} [{item.invitationCode}]</span>
                                <span className='info'>members: {item.members.length}</span>
                            </div>
                        </div>
                    </div>
                )
            })
            return <div className='posts'>{views}</div>
        }

        const {isModalOpen, active, wallets} = this.state
        if (isModalOpen !== Modal.Detail) return null
        if (!active) return null

        return (
            <SlideModal isOpen={isModalOpen === Modal.Detail}
                        onRequestClose={() => this.setState({isModalOpen: undefined, active: undefined})}
                        title={active.name}>
                <div className='panel-modal'>
                    {walletViews(wallets)}
                </div>
            </SlideModal>
        )
    }

    renderItems() {
        const {items} = this.state
        let views = items.map(item => {
            return (
                <div key={item.id} className='panel-item' onClick={() => this.onClickItem(item)}>
                    <img className='profile' alt={item.name} src={item.image}/>
                    <div className='detail'>
                        <div className='id'>id: {item.id}</div>
                        <div className='info'>name: {item.name}</div>
                        <div className='info'>email: {item.email}</div>
                        <div className='stats'>
                            <span className='info'>notification: {item.notification ? 'on' : 'off'}</span>
                            <span className='info'>{item.type === 'email' ? `email: ${item.email}` : `id: ${item.id}`}</span>
                        </div>
                    </div>
                </div>
            )
        })
        return <div>{views}</div>
    }

    render() {
        const {navItem} = this.props
        return (
            <div className='panel-container'>
                <PanelHeader title={this.props.panelKey.toUpperCase()}
                             count={this.state.count}
                             search={navItem} onSearchChanged={search => this.handleSearch(search)}/>

                <div className='body'>{this.renderItems()}</div>

                {this.renderDetail()}
            </div>
        )
    }
}

UserPanel.propTypes = {
    panelKey: PropTypes.string.isRequired
}

let mapDispatchToProps = (dispatch) => {
    return {
        navigateTo: (target, item) => dispatch(navigateTo(target, item)),
        updateProgress: (progress) => dispatch(updateProgress(progress))
    }
}

let mapStateToProps = (state) => {
    return {
        navItem: state.nav.item
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(UserPanel)))