import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Console, Landing, Login, Root } from './components'
import * as reducers from './reducers'
import './index.scss'
import './bootstrap.min.css'


const reducer = combineReducers({...reducers})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

ReactDOM.render(
    <AlertProvider template={AlertTemplate} timeout={5000}>
        <Provider store={store}>
            <Router>
                <Root>
                    <Switch>
                        <Route path='/login' component={Login}/>
                        <Route path='/console' component={Console}/>
                        <Route path='/' component={Landing}/>
                        <Redirect from='*' to='/'/>
                    </Switch>
                </Root>
            </Router>
        </Provider>
    </AlertProvider>
    , document.getElementById('root')
)